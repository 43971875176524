<template>
	<div>
		<el-card>
			<el-row>
				<el-button type="primary" @click="addLevel">新增技师门派</el-button>
			</el-row>
			<!-- 技师门派表单 -->
			<el-table border :data="menpaiData" style="width: 100%" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
				<el-table-column prop="menpai_id" label="ID"></el-table-column>
				<el-table-column prop="menpai_name" label="门派名称"></el-table-column>
				<el-table-column prop="sort" label="排序"></el-table-column>
				<el-table-column label="操作" width="150">
					<template slot-scope="scope">
						<el-button type="primary" plain size="small" @click="editLevel(scope.row)">编辑</el-button>
						<el-button type="danger" plain size="small" @click="removeLevel(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
			<!-- 新增对话框 -->
			<el-dialog :title="title" width="36%" :visible.sync="addLevelDialog">
				<el-form ref="addlevelForm" :model="addlevelForm" label-width="100px" :rules="addlevelRules">
					<el-form-item label="门派名称" prop="menpai_name">
						<el-input v-model="addlevelForm.menpai_name" maxlength="10" placeholder="请输入技师门派名称"></el-input>
					</el-form-item>
					<el-form-item label="排序" prop="sort">
						<el-input v-model.number="addlevelForm.sort" placeholder="请输入排序序号"></el-input>
					</el-form-item>
					<el-button type="default" @click="addLevelDialog=false">取消</el-button>
					<el-button type="primary" @click="editNewLevel">确定</el-button>
				</el-form>
			</el-dialog>
		</el-card>
	</div>
</template>

<script>
	let that;
	let timer = null;
	import Pagination from '@/components/Pagination/Pagination.vue'
	export default {
		components: {
			Pagination
		},
		data() {
			return {
				title: "新增技师门派",
				queryInfo: {
					query: "",
					pagenum: 1,
					pagesize: 10,
				},
				addLevelDialog: false,
				total: 0,
				addlevelForm: {
					menpai_name: '',
					sort: 99,
				},
				addlevelRules: {
					menpai_name: [{
						required: true,
						message: "请输入技师门派名称",
						trigger: "blur"
					}, ],
					sort: [{
							required: true,
							message: "请输入排序序号",
							trigger: "blur"
						},
						{
							type: "number",
							message: "请输入数字"
						},
					]
				},
				menpaiData: []
			}
		},
		created() {
			if (!this.$store.state.userInfo) {
		        // 防止未登录
		        this.$router.push({path: '/login'})
		    }else{
		    	that = this;
				this.getJishiMenpai();
			}
		},
		methods: {
			getJishiMenpai(){
				var url = 'jishi_menpai/menpai_list';
				let params = {
					menpai_name: this.queryInfo.query,
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.total = res.total;
						that.menpaiData = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			//新增技师门派
			addLevel() {
				this.title = "新增技师门派";
				this.addlevelForm = {menpai_name: '',sort: 99,};
				this.addLevelDialog = true
			},
			//确认新增
			editNewLevel() {
				this.$refs.addlevelForm.validate(valid=>{
		        	if(!valid){
		          		return;
			    	}else{
			    		if(timer != null){clearTimeout(timer);}
        				timer = setTimeout(() => {
							var url = 'jishi_menpai/addOrUpdate';
					        let params = that.addlevelForm;
					        that.fd_post(url, params).then((res) => {
				        		if(res.status){
				        			that.addLevelDialog = false;
				        			that.$refs.addlevelForm.resetFields();
				        			that.$message.success('操作成功');
				        			that.getJishiMenpai();
				        		}else{
				        			that.$message.error(res.msg);
				        		}
					        }).catch((err) => {
					            that.$message.error('网络错误');
					        });
					    },1000);
					}
		    	});
			},
			//打开编辑对话框
			editLevel(levelInfo) {
				this.title = "编辑技师门派";
				this.addLevelDialog = true
				for(let k in levelInfo) {
					this.addlevelForm[k] = levelInfo[k]
				}
			},
			//删除
			removeLevel(item) {
				this.$confirm('你确认要删除吗?', '温馨提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					var url = 'jishi_menpai/deleteMenpai';
			        let params = {
			        	menpai_id: item.menpai_id,
			        };
			        this.fd_post(url, params).then((res) => {
		        		if(res.status){
		        			this.$message.success('删除成功');
		        			this.getJishiMenpai();
		        		}else{
		        			this.$message.error(res.msg);
		        		}
			        }).catch((err) => {
			            this.$message.error('网络错误');
			        });
				}).catch(() => {
					this.$message.info('已取消删除')
				});
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1;
				this.queryInfo.pagesize = newSize;
				this.getJishiMenpai();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage;
				this.getJishiMenpai();
			},
		},
	}
</script>

<style lang="scss" scoped>
	@import './Technician_sect.scss';
</style>